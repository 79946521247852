/* eslint-disable react/prop-types */
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col, Hidden } from "react-grid-system"

// eslint-disable-next-line
import styled from "styled-components"
// eslint-disable-next-line
import BackgroundImage from "gatsby-background-image"
import AnimationLink from "../nav/AnimationLink"
import { rhythm } from "../../../config/typography"
import { below } from "../../../config/utilities"
import SocialIcons from "./SocialIcons"
import CTALayout2 from "../CTA/CTALayout2"

const BackgroundSection = ({ className, children }) => {
  const footerimage = useStaticQuery(
    graphql`
      {
        file(relativePath: { regex: "/footer/" }, extension: { eq: "png" }) {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={footerimage.file.childImageSharp.fluid}
      backgroundColor="#040e18"
    >
      {children}
    </BackgroundImage>
  )
}

const Footer = ({ data, variants }) => (
  <FooterWrap>
    <Wrapper>
      <Container fluid style={{ padding: `${rhythm(1.5)}` }}>
        <Row justify="center" style={{ alignItems: `baseline` }}>
          <Col
            md={2}
            sm={12}
            className="footermenu left"
            style={{ padding: 0 }}
          >
            <ul>
              <li>
                <a href="tel:917-727-4141">
                  <p style={{ margin: `0 auto` }}>917-727-4141</p>
                </a>
              </li>

              <li>
                <a href="mailto:info@pakd.media">
                  <p style={{ margin: `0 auto` }}>info@pakd.media</p>
                </a>
              </li>

              <li>
                <span>Brooklyn, NY</span>
              </li>
              <li>
                <span>Denver, CO</span>
              </li>
            </ul>
          </Col>

          <Col md={6} sm={12} style={{ padding: 0 }} className="CTA">
            <CTALayout2 />
          </Col>

          <Col
            md={2}
            sm={12}
            className="footermenu right"
            style={{ padding: 0 }}
          >
            <Hidden sm xs>
              <ul>
                {data.site.siteMetadata.menuLinks.map(link => (
                  <li key={link.key}>
                    <AnimationLink to={link.link}>{link.name}</AnimationLink>
                  </li>
                ))}
              </ul>
            </Hidden>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs="content">
            <SocialIcons iconCol="#ffffff" />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  </FooterWrap>
)

export default Footer

const FooterWrap = styled.footer`
  grid-area: footer;
  min-height: 100%;
`

const Wrapper = styled(BackgroundSection)`
  background-color: ${props => props.theme.colors.primarydark};

  .CTA {
    ${below.s`
    margin-bottom:${rhythm(1.33)};
       order:1;
      `}
  }
  .footermenu {
    ul * {
      list-style: none;
      line-height: 1.35rem;
      font-family: Teko;
      font-weight: 400;
      text-transform: uppercase;
    }
    ul {
      margin: auto;

      ${below.s`
        display:flex;
        justify-content:space-around;
      `}
      li {
        span {
          color: #a6a6a6;
        }
      }
    }
  }
  .right {
    text-align: right;
    ${below.s`
       order:2;
       
      `}
  }
  .left {
    text-align: left;

    ${below.s`
       order:3;
       
      `}
  }
  #socialicons {
    a {
      opacity: 0.66;
      transition: ${props => props.theme.transitions.circ};
      svg {
        transform: scale(1);
        transition: all 0.25s ease-in-out;
      }
      &:hover {
        opacity: 1;
        transition: ${props => props.theme.transitions.circ};

        svg {
          transform: scale(1.33);
          transition: all 0.25s ease-in-out;
        }
      }
    }
  }
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: social;
  #socialicons {
    a {
      padding: ${rhythm(0.25)} 0;
      opacity: 0.25;
      transition: ${props => props.theme.transitions.circ};
      svg {
        transform: scale(0.66);
        transition: ${props => props.theme.transitions.circ};
      }
      &:hover {
        opacity: 1;
        transition: ${props => props.theme.transitions.circ};
        svg {
          transform: scale(0.75);
          transition: ${props => props.theme.transitions.circ};
        }
      }
    }
  }
`

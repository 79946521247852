import React from "react"
import { motion } from "framer-motion"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"
import { useSiteQuery } from "../queries/SITE_QUERIES"
import Footer from "../components/footer/Footer"
import { useInView } from "react-intersection-observer"

const mainVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      delayChildren: 5,
      when: `beforeChildren`,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      delayChildren: 1,
    },
  },
}
const footerVariants = {
  initial: {
    y: 300 + `px`,
    opacity: 0,
  },
  enter: {
    y: 0 + `px`,
    opacity: 1,
  },
  exit: {
    y: 300 + `px`,
    opacity: 1,
  },
}
const TransitionContext = ({ children, location }) => {
  const state = useTransitionState()
  const data = useSiteQuery()
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <>
      <motion.div
        key={location.pathname}
        variants={mainVariants}
        initial="initial"
        animate={
          [`entering`, `entered`].includes(state.transitionStatus)
            ? `enter`
            : `exit`
        }
        exit="exit"
      >
        {children}
        <span
          ref={ref}
          style={{ position: `absolute`, bottom: 0, left: 0, height: `2px` }}
        />

        <motion.div
          variants={footerVariants}
          key="footer"
          initial="initial"
          animate={
            [`entering`, `entered`].includes(state.transitionStatus)
              ? `enter`
              : `exit`
          }
          exit="exit"
          transition={{
            delay: 0.33,
            damping: 180,
            stiffness: 1.5,
          }}
        >
          <Footer data={data} />
        </motion.div>
      </motion.div>
    </>
  )
}

export default TransitionContext

import React from "react"
import {
  FaDribbble,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaVimeoV,
  FaYoutube,
} from "react-icons/fa"
import { Row, Col } from "react-grid-system"
const socialChannels = [
  {
    count: `1`,
    name: `Dribbble`,
    icon: <FaDribbble color="white" />,
    url: `https://www.dribbble.com/pakdmedia/`,
  },
  {
    count: `2`,
    name: `Facebook`,
    icon: <FaFacebookF color="white" />,
    url: `https://www.facebook.com/pakdmedia/`,
  },
  {
    count: `3`,
    name: `Instagram`,
    icon: <FaInstagram color="white" />,
    url: `https://www.instagram.com/pakdmedia/`,
  },
  // {
  //   count: '4',
  //   name: 'LinkedIn',
  //   icon: <FaLinkedinIn color="white"/>,
  //   url: 'https://www.linkedin.com/company/pakdmedia',
  // },
  {
    count: `5`,
    name: `Twitter`,
    icon: <FaTwitter color="white" />,
    url: `https://www.twitter.com/pakdmedia/`,
  },
  {
    count: `6`,
    name: `Vimeo`,
    icon: <FaVimeoV color="white" />,
    url: `https://www.vimeo.com/pakdmedia/`,
  },
  {
    count: `7`,
    name: `YouTube`,
    icon: <FaYoutube color="white" />,
    url: `https://www.youtube.com/channel/UCWdos5PN6zND13mhp9zU0Og`,
  },
]
const SocialIcons = () => (
  <Row id="socialicons" className="innerwrap" align="center" justify="center">
    {socialChannels.map(socialChannel => (
      <Col key={socialChannel.name} xs="content">
        <div key={socialChannel.name}>
          <a
            href={socialChannel.url}
            target="_blank"
            title={socialChannel.name}
            rel="noopener noreferrer"
          >
            {socialChannel.icon}
          </a>
        </div>
      </Col>
    ))}
  </Row>
)

export default SocialIcons

import React, { PureComponent } from "react"
import { Header1, Button } from "../../elements"

export default class CTALayout2 extends PureComponent {
  render() {
    return (
      <>
        <Header1
          lineOne="Intrigued?"
          lineTwo="We'd love to hear from you."
          secondary
          marginbottom={`1px`}
        />
        <Button secondary linkTo="/contact">
          Start a Project
        </Button>
      </>
    )
  }
}
